@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';
@use '../../styleguide/mixins.scss';

.root {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid colors.$grey;
  background-color: white;
  text-decoration: none;
  color: colors.$default-text;
  position: relative;
  width: 100%;

  @media screen and (min-width: vars.$extra-large) {
    width: 364px;
  }
}

.discount {
  position: absolute;
  display: flex;
  padding: 4px;
  align-items: center;
  top: -11px;
  border-radius: 4px;
  background-color: #e53035;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-stretch: condensed;
  color: white;
}

.productContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 10px;
  width: 100%;
}

.brandFooter {
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
}

.brandLogo {
  width: 100px;
  height: 44px;
  display: flex;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.brandInfo {
  font-stretch: condensed;
}

.brandName {
  font-weight: 700;
}

.brandItems {
  font-weight: 400;
}

.product {
  width: calc(50% - 2px);
  height: 80px;
  background-color: colors.$white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(100, 100, 100, 0.15);
}
